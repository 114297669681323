import { Theme } from "theme-ui";
import { toTheme } from "@theme-ui/typography";
import * as github from "typography-theme-github";
import * as merge from "deepmerge";

const createTheme = () => {
  const githubTheme = toTheme(github);

  const theme: Theme = merge(githubTheme, {
    fonts: {
      heading: "IBM Plex Sans, " + githubTheme.fonts.heading,
      body:
        "'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
    },
    sizes: {
      container: "600px"
    },
    colors: {
      text: "#333",
      background: "#fff",
      primary: "#c04848",
      secondary: "#622774",
      inverted: {
        text: "#fff",
        background: "#333",
        primary: "#c04848",
        secondary: "#622774"
      }
    },
    styles: {
      root: {
        m: 0,
        p: 0
      },
      a: {
        color: "primary",
        ":hover": {
          color: "secondary"
        }
      }
    }
  });

  return theme;
};

export default createTheme;
