/// <reference types="react-dom/experimental" />
import * as React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";
import { ThemeProvider } from "theme-ui";
import createTheme from "./theme";
import {
  createClient,
  ssrExchange,
  cacheExchange,
  dedupExchange,
  fetchExchange,
  Provider as URQLProvider
} from "urql";
import { loadableReady } from "@loadable/component";
import { HelmetProvider } from "react-helmet-async";

declare global {
  interface Window {
    __GRAPHQL_ENDPOINT__: string;
    __GRAPHQL_CACHE__: any;
  }
}

const ssrCache = ssrExchange({
  isClient: true,
  initialState: window.__GRAPHQL_CACHE__
});

const client = createClient({
  url: window.__GRAPHQL_ENDPOINT__,
  exchanges: [dedupExchange, cacheExchange, ssrCache, fetchExchange]
});

loadableReady().then(() => {
  hydrate(
    <HelmetProvider>
      <URQLProvider value={client}>
        <ThemeProvider theme={createTheme()}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </URQLProvider>
    </HelmetProvider>,
    document.getElementById("root")
  );
});
