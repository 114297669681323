/** @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import { Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";
const Home = loadable(() => import("./Home"));
const Articles = loadable(() => import("./Articles"));
const Article = loadable(() => import("./Article"));

const App = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/articles" component={Articles} />
    <Route exact path="/articles/:slug" component={Article} />
  </Switch>
);

export default App;
